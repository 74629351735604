import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Nuestro compromiso' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
      En Cashfy, asumimos con responsabilidad nuestro 
      compromiso con nuestros usuarios en el ámbito de las noticias 
      y productos financieros. Si necesitas orientación sobre tus inversiones,
       deseas conocer las últimas noticias financieras o tienes preguntas 
       sobre nuestros servicios, no dudes en ponerte en contacto con nosotros.
      </Text>
    </Card>
  </Section>
)

export default Commitment
