import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContacto = props => (
  <Layout {...props}>
    <Seo title='Contacto' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Comunicate con nosotros"
          subheader='¿Tenes alguna pregunta, comentario o sugerencia? ¡Nos encantaría escucharte!.'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContacto
